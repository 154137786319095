export default function BallsSections({ type = 1, ...props }) {
  return type === 1 ? (
    <section className="relative h-fit py-20 md:py-48 w-full bg-white z-10 overflow-hidden">
      {props.children}
      <div className="w-64 h-64 absolute mb-10 -bottom-36 left-16 z-10 rounded-full bg-palette-orange md:bg-palette-dark-orange" />
      <div className="w-80 h-80 hidden md:block absolute mb-14 -bottom-40 -left-24 z-10 rounded-full bg-palette-orange" />
      <div className="w-64 h-64 absolute -bottom-40 left-48 z-10 rounded-full bg-palette-blue" />
      <div className="w-96 h-96 hidden md:block absolute mb-4 -bottom-52 -right-40 z-10 rounded-full bg-palette-dark-purple" />
      <div className="w-52 h-52 absolute mb-6 -bottom-40 right-52 z-10 rounded-full bg-palette-light-purple" />
      <div className="w-72 h-72 hidden md:block absolute mb-2 -bottom-48 right-10 z-10 rounded-full bg-palette-blue" />
    </section>
  ) : (
    <section className="relative h-fit py-20 md:pb-64 md:pt-36 w-full bg-white z-10">
      {props.children}
      <div className="w-96 h-96 hidden md:block absolute mb-4 -bottom-28 -right-20 z-10 rounded-full bg-palette-dark-purple" />
      <div className="w-80 h-80 absolute mb-6 -bottom-52 -right-20 z-10 rounded-full bg-palette-light-purple" />
      <div className="w-64 h-64 hidden md:block absolute mb-2 -bottom-32 right-24 z-10 rounded-full bg-palette-blue" />
      <div className="w-80 h-80 hidden md:block absolute mb-14 -bottom-24 -left-20 z-10 rounded-full bg-palette-orange md:bg-palette-dark-orange" />
      <div className="w-72 h-72 absolute mb-10 -bottom-52 -left-16 z-10 rounded-full bg-palette-orange" />
      <div className="w-64 h-64 absolute -bottom-32 left-20 z-10 rounded-full bg-palette-blue" />
    </section>
  );
}
