import { useEffect, useState } from "react";
import Footer from "../components/home/footer";
import { Helmet } from "react-helmet";

export default function Assistance({}) {
  const [code, setCode] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Assistance - CCO</title>
        <meta
          name="description"
          content="Vous avez besoin d'assistance ? Contactez-nous par téléphone ou par mail. Vous pouvez également vous connecter à une session à distance."
        />
        <meta name="keywords" content="CCO, assistance, dépannage, support, session, distance, téléassistance, téléphone, mail" />
      </Helmet>
      <main>
        <script type="text/javascript" src="https://www.islonline.net/webapi/api.js?libs=join&amp;webcallurl=https://www.islonline.net/__webcall__"></script>
        <img width={1440} height={1464.87} src="/images/blur_background.png" alt="blur" className="w-full h-full fixed bg-cover blur-sm -z-10" />
        <div className="h-screen w-full p-10">
          <div className="w-full max-w-4xl mx-auto h-fit bg-white rounded-3xl shadow-lg flex flex-col items-center p-10 slide-in">
            <h5 className="text-center text-palette-blue uppercase tracking-wider font-bold">Assistance</h5>
            <p className="text-center text-palette-blue w-2/3">
              En cas de panne, vous pouvez nous contacter au <strong>05 36 16 00 06</strong> ou nous envoyer un mail sur <strong>support@cco-info.fr</strong>
            </p>
            <hr className="mx-4 w-full mt-5 mb-14 border-opacity-20" />
            <p className="mb-5 text-lg">Connectez-vous à la session à distance</p>
            <div className="flex flex-row w-4/5 space-x-2 justify-center">
              <input
                id="code_session"
                className="border rounded-lg w-2/4 px-4 py-1.5"
                onChange={(e) => setCode(e.target.value)}
                placeholder="Entrer le code de session"
              />
              <a
                href={"https://www.islonline.net/users/start/ISLLightClient?addbase=" + code + "&cmdline=--auto-close+--connect+" + code}
                className="px-5 py-2.5 w-fit text-sm sm:text-base hover:shadow focus:ring-0 font-semibold focus:outline-none rounded-lg bg-palette-blue text-white"
              >
                Connexion
              </a>
            </div>
            <p className="my-5 text-lg">- ou -</p>
            <a
              href="Assistance_CCO.exe"
              className="px-5 py-2.5 w-fit text-sm sm:text-base hover:shadow hover:font-semibold focus:ring-0 focus:outline-none rounded-lg border border-palette-blue text-palette-blue"
            >
              Télécharger l'application
            </a>
            <div className="h-full" />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
