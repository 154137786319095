import { useState } from "react";
import Dropzone from "react-dropzone";
import texts from "../../data/recrutement.json";

export default function FormRecrutement({ poste = "libre", service = 0, ...props }) {
  const [form, setForm] = useState({});
  const [file, setFile] = useState(null);
  const [sent, setSent] = useState(false);

  function handleDrop(files) {
    if (files.length > 1) return alert("Il ne peut pas y avoir plusieurs fichiers");
    if (files.length === 0) return alert("Aucun fichier sélectionné");
    if (files[0].name.slice(files[0].name.length - 3) !== "pdf") return alert("Le fichier doit être de type .pdf");
    setFile(files[0]);
  }

  async function handleForm() {
    const formData = new FormData();
    const controller = new AbortController();
    let myHeaders = new Headers();
    if (file) formData.append("file", file, file.name);
    formData.append("nom", form.nom);
    formData.append("ville", form.ville);
    formData.append("email", form.email);
    formData.append("poste", poste);

    myHeaders.append("APIKEY", "iubfzaIB86ZAFkbUZOIAF65OVABNE3lqsoiub3684FE");

    fetch("/api/recrutement.php", {
      method: "POST",
      headers: myHeaders,
      signal: controller.signal,
      credentials: "include",
      body: formData,
    });

    setSent(true);
  }

  return sent ? (
    <div
      className={"mx-auto max-w-2xl space-y-4 flex flex-col items-center my-20 text-palette-" + (service === 0 ? "blue" : service === 1 ? "purple" : "orange")}
    >
      <p className="text-center text-2xl font-semibold ">Candidature envoyée avec succès !</p>
      <p className="text-center">Nous vous contacterons bientôt.</p>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-24 h-24">
        <path
          fillRule="evenodd"
          d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  ) : (
    <>
      <label className="text-sm font-bold">{texts.form.label1}</label>
      <input
        id="nom"
        onChange={(e) => setForm({ ...form, [e.target.id]: e.target.value })}
        placeholder={texts.form.placeholder1}
        className="w-full rounded-none text-sm mb-5 outline-0 border-b border-gray-400 px-4 py-2 placeholder:text-gray-400"
      />
      <label className="text-sm font-bold">{texts.form.label2}</label>
      <input
        id="ville"
        onChange={(e) => setForm({ ...form, [e.target.id]: e.target.value })}
        placeholder={texts.form.placeholder2}
        className="w-full rounded-none text-sm mb-5 outline-0 border-b border-gray-400 px-4 py-2 placeholder:text-gray-400"
      />
      <label className="text-sm font-bold">{texts.form.label3}</label>
      <input
        id="email"
        onChange={(e) => setForm({ ...form, [e.target.id]: e.target.value })}
        placeholder={texts.form.placeholder3}
        className="w-full rounded-none text-sm mb-5 outline-0 border-b border-gray-400 px-4 py-2 placeholder:text-gray-400"
      />
      <label className="text-sm font-bold">{texts.form.label4}</label>
      <div className="md:hidden overflow-hidden">
        <input type="file" onChange={handleDrop} />
      </div>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="hidden md:flex flex-col text-center items-center justify-center p-10 border rounded-2xl border-gray-500 border-dashed"
          >
            {file && (
              <>
                <p>{file.name}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFile(null);
                  }}
                >
                  <path
                    fillRule="evenodd"
                    d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            )}
            {!file && (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
                <p style={{ pointerEvents: "none" }} className="">
                  {texts.form.placeholder4}
                </p>
              </>
            )}
          </div>
        )}
      </Dropzone>
      <p>Taille maximale du fichier : 2MB</p>
      <button onClick={handleForm} className="w-full rounded-lg bg-palette-dark-blue text-white font-semibold text-center mt-10 px-6 py-3">
        {texts.form.button}
      </button>
    </>
  );
}
