import { useEffect } from "react";
import texts from "../../data/home.json";
import { PrimaryButtonLink } from "../buttons";
import { awaitForSeconds } from "../../helpers/functions";

let generating = false;
export default function Header({}) {
  useEffect(() => {
    writeLabel();
  }, []);

  async function writeLabel() {
    if (generating) return;
    generating = true;
    for (let index = 0; index < 2; index++) {
      document.getElementById("label").innerText = "|";
      await awaitForSeconds(0.3);
      document.getElementById("label").innerText = "";
      await awaitForSeconds(0.3);
    }

    let writeText = texts.header.label;
    while (writeText.length > 0 && document.getElementById("label") != null) {
      let char = writeText.slice(0, 1);
      if (char === " ") {
        if (writeText[1] === "|")
          for (let index = 0; index < 2; index++) {
            let text = document.getElementById("label").innerText;
            document.getElementById("label").innerText = text + "|";
            await awaitForSeconds(0.2);
            document.getElementById("label").innerText = text;
            await awaitForSeconds(0.2);
          }
        char = writeText.slice(0, 2);
        writeText = writeText.slice(2);
      } else writeText = writeText.slice(1);

      document.getElementById("label").innerText += char;
      for (let index = 0; index < 1; index++) {
        let text = document.getElementById("label").innerText;
        document.getElementById("label").innerText = text + "|";
        await awaitForSeconds(0.01);
        document.getElementById("label").innerText = text;
        await awaitForSeconds(0.01);
      }
    }

    for (let index = 0; index < 2; index++) {
      let text = document.getElementById("label").innerText;
      document.getElementById("label").innerText = text + "|";
      await awaitForSeconds(0.3);
      document.getElementById("label").innerText = text;
      await awaitForSeconds(0.3);
    }
  }

  return (
    <header className="header w-full sm:fixed overflow-hidden">
      <img
        src="/images/header_background.jpg"
        alt="header"
        width={1440}
        height={880}
        className="w-full h-full absolute top-0 bg-gradient-to-t from-white to-transparent object-cover opacity-10 -z-20"
      />
      <div className="w-full h-full absolute bg-gradient-to-t from-white to-transparent opacity-50 -z-10 bg-cover" />
      <img width={750} height={750} src="/images/logo.svg" alt="header" className="absolute opacity-40 md:right-10 top-2/4 sm:top-2/4 lg:top-1/4 -z-10" />
      <div className="h-full flex flex-col justify-center max-w-7xl mx-auto space-y-2 sm:space-y-4 p-4 py-24">
        <p id="label" className="sm:text-xl h-4 slide-in">
          {generating ? texts.header.label : ""}
        </p>
        <h1 className="slide-in max-w-3xl">{texts.header.title}</h1>
        <h2 className="text-lg sm:text-xl text-palette-light-text sm:w-3/4 md:w-2/4 slide-in">{texts.header.subtitle}</h2>
        <div className="flex items-center space-x-2 slide-in">
          <PrimaryButtonLink to="/societe" outlined={false}>
            La société
          </PrimaryButtonLink>
          <PrimaryButtonLink to="/contact" outlined={true}>
            Contact
          </PrimaryButtonLink>
        </div>
      </div>
    </header>
  );
}
