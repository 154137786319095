import { useEffect } from "react";
import { Helmet } from "react-helmet";
import texts from "../../data/cloud.json";
import Banner from "../../components/services/banner";
import NavBar from "../../components/navigation/navBar";
import Companies from "../../components/sections/comapnies";
import CompanyLogo from "../../components/sections/companyLogo";
import ServiceStructure from "../../components/services/structure";
import BallsSections from "../../components/sections/ballsSection";

export default function Cloud({}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cloud - CCO</title>
        <meta
          name="description"
          content="Le service Cloud de CCO vous propose des solutions de stockage, de sauvegarde, de sécurité et de virtualisation. Découvrez nos partenaires et nos solutions."
        />
        <meta name="keywords" content="cloud, stockage, sauvegarde, sécurité, virtualisation" />
      </Helmet>
      <NavBar />
      <Banner
        path={"M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z"}
        title="Cloud"
        colorHex={"#DB9037"}
        additionalCss="bg-gradient-to-tr from-palette-orange to-palette-light-orange"
        color="dark-orange"
      />
      <div className="h-40 sm:h-52 pointer-events-none relative -z-30"></div>
      <ServiceStructure color="dark-orange" colorHex={"#DB9037"} words={texts.words} mail={texts.mail}>
        <BallsSections>
          <div className="h-full flex flex-col justify-center items-center mx-auto max-w-6xl pb-20">
            <h5 className="text-palette-orange text-center slide-in">{texts.solutions.title}</h5>
            <p className="text-base sm:text-xl max-w-xl text-center px-2 mx-auto mt-4 slide-in mb-10 sm:mb-0">{texts.solutions.subtitle}</p>
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-10 p-4 sm:p-8 md:p-16">
              {texts.solutions.items.map((item) => (
                <li className="flex space-x-5 w-full slide-in">
                  <div className="p-4 md:p-6 h-fit shadow flex gradient-orange rounded-xl md:rounded-3xl transition-all duration-200 ease-in-out hover:from-white hover:to-white border hover:border-palette-orange group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-8 md:w-12 h-8 md:h-12 text-white group-hover:text-palette-orange transition-all duration-200 ease-in-out"
                    >
                      <path fillRule="evenodd" d={item.pathSvg} clipRule="evenodd" />
                      {item.pathSvg2 && <path d={item.pathSvg2} />}
                      {item.pathSvg3 && <path d={item.pathSvg3} />}
                      {item.pathSvg4 && <path d={item.pathSvg4} />}
                    </svg>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h6>{item.title}</h6>
                    <p>{item.subtitle}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </BallsSections>
        <Companies additionalCss="from-palette-dark-orange to-palette-light-orange" title={texts.partenaires.title}>
          <CompanyLogo
            path={"/images/logos_cloud/apache.png"}
            pathColor={"/images/logos_cloud/apache_color.png"}
            width={197.24}
            height={80}
            alt="Logo d'Apache"
          />
          <CompanyLogo
            path={"/images/logos_cloud/microsoft.png"}
            pathColor={"/images/logos_cloud/microsoft_color.png"}
            width={267.91}
            height={64}
            alt="Logo de Microsoft"
          />
          <CompanyLogo
            path={"/images/logos_cloud/veeam.png"}
            pathColor={"/images/logos_cloud/veeam_color.png"}
            width={170.85}
            height={64}
            alt="Logo de Veeam"
          />
          <CompanyLogo
            path={"/images/logos_cloud/phpMyAdmin.png"}
            pathColor={"/images/logos_cloud/phpMyAdmin_color.png"}
            width={121.02}
            height={80}
            alt="Logo de phpMyAdmin"
          />
        </Companies>
        <section className="relative z-10 bg-white py-36">
          <article className="max-w-3xl mx-auto p-3 flex flex-col w-full space-y-6">
            <h3 className="w-full">{texts.info.title1}</h3>
            <div className="w-full flex flex-col">
              <p className="text-xl text-gray-400 max-w-4xl">{texts.info.subtitle1}</p>
              <p className="text-xl text-gray-400 max-w-4xl">{texts.info.subtitle2}</p>
            </div>
            <img
              width={640}
              height={480}
              src={texts.info.img1}
              className="w-full h-96 object-cover bg-gray-200 rounded-xl sm:rounded-3xl"
              alt="Illustration d'un serveur et le cloud"
            />
          </article>
        </section>
      </ServiceStructure>
    </>
  );
}
