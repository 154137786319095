import { useEffect } from "react";
import { Helmet } from "react-helmet";
import texts from "../../data/developpement.json";
import Banner from "../../components/services/banner";
import NavBar from "../../components/navigation/navBar";
import Companies from "../../components/sections/comapnies";
import CompanyLogo from "../../components/sections/companyLogo";
import ServiceStructure from "../../components/services/structure";
import BallsSections from "../../components/sections/ballsSection";

export default function Developpement({}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Développement - CCO</title>
        <meta
          name="description"
          content="Le service de développement de CCO vous propose des solutions de développement web, mobile et logiciel. Découvrez nos partenaires et nos solutions."
        />
        <meta name="keywords" content="développement, web, mobile, logiciel, app" />
      </Helmet>
      <NavBar />
      <Banner
        path={"M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"}
        title="Développement"
        colorHex={"#5D3D7C"}
        additionalCss="bg-gradient-to-tr from-palette-dark-purple to-palette-light-purple"
        color="dark-purple"
      />
      <div className="h-40 sm:h-52 pointer-events-none relative -z-30"></div>
      <ServiceStructure color="dark-purple" colorHex={"#5D3D7C"} words={texts.words} mail={texts.mail}>
        <BallsSections>
          <div className="h-full text-center flex flex-col justify-center px-2 items-center mx-auto max-w-7xl pb-20">
            <h5 className="text-palette-dark-purple tracking-tight slide-in">{texts.process.title}</h5>
            <p className="text-xl max-w-xl text-center mx-auto mt-4 slide-in mb-10 md:mb-0">{texts.process.subtitle}</p>
            <ul className="flex flex-col md:flex-row justify-between space-y-10 items-center md:space-y-0 md:w-5/6 mx-auto md:space-x-4 md:mt-16 py-10 ">
              {texts.process.items.map((item, k) => (
                <>
                  {k > 0 && (
                    <svg width="164" height="52" viewBox="0 0 164 52" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden md:block">
                      <path
                        d="M162.906 3.78164C163.177 3.83358 163.439 3.65582 163.491 3.3846C163.543 3.11339 163.365 2.85142 163.094 2.79949L162.906 3.78164ZM0.21917 49.3906C19.5763 53.9166 33.3757 52.1478 44.7095 47.2036C56.0141 42.2721 64.8323 34.1902 74.2112 26.2117C83.6068 18.2188 93.5981 10.2954 107.319 5.52178C121.033 0.750275 138.518 -0.88831 162.906 3.78164L163.094 2.79949C138.572 -1.89626 120.903 -0.263392 106.99 4.57731C93.0829 9.41588 82.9726 17.4454 73.5632 25.45C64.137 33.4688 55.4467 41.4286 44.3097 46.287C33.202 51.1325 19.6268 52.9015 0.446845 48.4169L0.21917 49.3906Z"
                        fill="#1A202C"
                      />
                    </svg>
                  )}
                  <li className="flex space-x-4 w-full md:w-min justify-center items-start">
                    <div className="p-4 md:p-6 w-fit shadow flex gradient-purple rounded-xl md:rounded-3xl slide-in transition-all duration-200 ease-in-out hover:from-white hover:to-white border hover:border-palette-purple group">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-8 md:w-12 h-8 md:h-12 text-white group-hover:text-palette-purple transition-all duration-200 ease-in-out"
                      >
                        <path fillRule="evenodd" d={item.pathSvg} clipRule="evenodd" />
                        {item.pathSvg2 && <path fillRule="evenodd" d={item.pathSvg2} clipRule="evenodd" />}
                      </svg>
                    </div>
                    <div className="flex w-full text-left leading-tight flex-col space-y-2 slide-in md:hidden">
                      <h6>{item.title}</h6>
                      <p className="text-base max-w-sm">{item.subtitle}</p>
                    </div>
                  </li>
                </>
              ))}
            </ul>
            <ul className="justify-between w-full px-4 lg:px-0 hidden md:flex">
              {texts.process.items.map((item, k) => (
                <li className="flex text-center w-1/4 leading-tight px-2 flex-col space-y-2 slide-in">
                  <h6>{item.title}</h6>
                  <p>{item.subtitle}</p>
                </li>
              ))}
            </ul>
          </div>
        </BallsSections>
        <Companies additionalCss="from-palette-dark-purple to-palette-light-purple" title={texts.partenaires.title}>
          <CompanyLogo path={"/images/logos_dev/MySql.png"} pathColor={"/images/logos_dev/MySql_color.png"} width={94.6} height={64} alt="Logo de MySql" />
          <CompanyLogo path={"/images/logos_dev/php.png"} pathColor={"/images/logos_dev/php_color.png"} width={122.9} height={64} alt="Logo de PHP" />
          <CompanyLogo
            path={"/images/logos_dev/symfony.png"}
            pathColor={"/images/logos_dev/symfony_color.png"}
            width={252.12}
            height={64}
            alt="Logo de Symfony"
          />
          <CompanyLogo path={"/images/logos_dev/react.png"} pathColor={"/images/logos_dev/react_color.png"} width={56.93} height={64} alt="Logo de React" />
          <CompanyLogo
            path={"/images/logos_dev/flutter.png"}
            pathColor={"/images/logos_dev/flutter_color.png"}
            width={226.76}
            height={64}
            alt="Logo de Flutter"
          />
        </Companies>
        <section className="relative z-10 bg-white py-20">
          <div className="max-w-7xl flex flex-col space-y-20 md:space-y-0 px-4 md:flex-row mx-auto">
            <article className="flex md:w-2/4 flex-col-reverse md:flex-col md:mr-10 space-y-2 md:space-y-5">
              <div className="w-full flex p-2 flex-col space-y-2 mt-5 md:mt-0 justify-center">
                <h3 className="">{texts.info.title1}</h3>
                <p className="text-base md:text-xl text-justify text-gray-400">{texts.info.subtitle1}</p>
              </div>
              <img width={640} height={480} src={texts.info.img1} className="w-full object-cover bg-gray-200 rounded-2xl md:rounded-b-3xl md:rounded-t-none" />
            </article>
            <article className="flex md:w-2/4 flex-col space-y-2 md:ml-10 md:space-y-5">
              <img width={640} height={480} src={texts.info.img2} className="w-full object-cover bg-gray-200 rounded-2xl md:rounded-t-3xl md:rounded-b-none" />
              <div className="w-full flex p-2 flex-col space-y-2 justify-center">
                <h3 className="">{texts.info.title2}</h3>
                <p className="text-base md:text-xl text-justify text-gray-400">{texts.info.subtitle2}</p>
              </div>
            </article>
          </div>
        </section>
      </ServiceStructure>
    </>
  );
}
