import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HistoryProvider from "./helpers/historyProvider";
import Infrastructure from "./pages/services/infrastructure";
import Cloud from "./pages/services/cloud";
import Developpement from "./pages/services/developpement";
import Societe from "./pages/societe";
import Recrutement from "./pages/recrutement";
import Contact from "./pages/contact";
import Assistance from "./pages/assistance";
import NosEngagements from "./pages/engagements";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter history={HistoryProvider}>
      <Routes>
        <Route path="/assistance" element={<Assistance />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/recrutement" element={<Recrutement />} />
        <Route path="/societe" element={<Societe />} />
        <Route path="/engagements" element={<NosEngagements />} />
        <Route path="/services/developpement" element={<Developpement />} />
        <Route path="/services/cloud" element={<Cloud />} />
        <Route path="/services/infrastructure" element={<Infrastructure />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
