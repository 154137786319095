
export default function Banner({ title = "", colorHex, additionalCss = "", color = "", path }) {
  return (
    <div className={"w-screen h-40 sm:h-52 flex items-end fixed overflow-hidden " + additionalCss}>
      <img width={1440} height={179} src="/images/header_background.jpg" alt="header" className="w-full -bottom-1 absolute bg-cover opacity-30 h-64 blur" />
      <div className="relative  max-w-7xl  mx-auto w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke={colorHex}
          className={"absolute w-80 h-80 -bottom-24 -right-28 opacity-70 sm:blur-0 md:right-10  text-palette-" + color}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d={path} />
        </svg>
        <h1 className="text-white text-4xl md:text-7xl relative z-10 font-bold px-1 py-3 sm:p-3 slide-in">{title}</h1>
      </div>
    </div>
  );
}
