import { Link } from "react-router-dom";

export function PrimaryButton({ outlined = false, onClick = null, ...props }) {
  return (
    <button
      onClick={onClick}
      className={
        "px-5 py-3 text-sm sm:text-base focus:ring-0 focus:outline-none rounded-full " +
        (outlined ? "border border-palette-text backdrop-blur" : " bg-palette-text text-white")
      }
    >
      {props.children}
    </button>
  );
}

export function PrimaryButtonLink({ outlined = false, to = "", ...props }) {
  return (
    <Link
      to={to}
      className={
        "px-5 py-3 text-sm sm:text-base focus:ring-0 focus:outline-none rounded-full " +
        (outlined ? "border border-palette-text backdrop-blur-sm" : " bg-palette-text text-white")
      }
    >
      {props.children}
    </Link>
  );
}
