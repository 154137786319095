import Footer from "../home/footer";

export default function ServiceStructure({ color, colorHex, words, mail, ...props }) {
  return (
    <>
      <div className={"bg-palette-" + color + " w-full relative top-0 overflow-hidden"}>
        <div class="relative  overflow-x-hidden hidden sm:flex">
          <div class="py-4 animate-marquee whitespace-nowrap">
            {words.map((e) => (
              <span
                key={"key_1" + e}
                className="text-3xl sm:text-7xl mx-4 font-bold sm:font-outline-2 text-gray-50 sm:text-transparent uppercase whitespace-nowrap"
              >
                {e}
              </span>
            ))}
          </div>
          <div class="absolute top-0 py-4 animate-marquee2 whitespace-nowrap">
            {words.map((e) => (
              <span
                key={"key_2" + e}
                className="text-3xl sm:text-7xl mx-4 font-bold sm:font-outline-2 text-gray-50 sm:text-transparent uppercase whitespace-nowrap"
              >
                {e}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={"hidden sm:block bg-palette-" + color + " w-full sticky z-20 top-0 h-0 "}>
        <svg viewBox="0 0 1440 115" fill="none" xmlns="http://www.w3.org/2000/svg" className={"w-full relative -top-1 text-palette-" + color + ""}>
          <path d="M0 0H1440V60.2973L0 115V0Z" fill={colorHex} />
        </svg>
      </div>
      <main>{props.children}</main>
      <div className="bg-white relative z-20  w-full h-fit">
        <svg viewBox="0 0 1440 134" fill="none" xmlns="http://www.w3.org/2000/svg" className={"w-full relative -bottom-1 text-palette-" + color + " "}>
          <path d="M0 56L1440 0.000251778L1440 134L0.000228348 134L0 56Z" fill={colorHex} />
        </svg>
      </div>
      <div className={"bg-palette-" + color + " w-full h-24 flex relative z-20 items-center justify-center"}>
        <address>
          <a className="text-white text-center font-normal not-italic text-xl md:text-4xl" href={"mailto:" + mail}>
            {mail}
          </a>
        </address>
      </div>
      <div className={"bg-palette-" + color + " w-full relative z-20 justify-center sm:p-4"}>
        <Footer isService={true} />
      </div>
    </>
  );
}
