import texts from "../data/contact.json";
import { useEffect, useState } from "react";
import Footer from "../components/home/footer";
import NavBar from "../components/navigation/navBar";
import { Helmet } from "react-helmet";

export default function Contact({}) {
  const [form, setForm] = useState({});
  const [sent, setSent] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleForm() {
    const controller = new AbortController();
    let myHeaders = new Headers();
    let myInit = {
      headers: myHeaders,
      signal: controller.signal,
      method: "POST",
      body: JSON.stringify(form),
    };
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("APIKEY", "iubfzaIB86ZAFkbUZOIAF65OVABNE3lqsoiub3684FE");

    fetch("/api/contact.php", myInit);

    setSent(true);
  }

  return (
    <>
      <Helmet>
        <title>Contact - CCO</title>
        <meta name="description" content="Vous avez une question, une demande ou une suggestion ? Contactez-nous dès maintenant !" />
        <meta name="keywords" content="CCO, contact, question, demande, suggestion, message, mail, téléphone" />
      </Helmet>
      <NavBar />
      <main>
        <img width={1440} height={1464.87} src="/images/blur_background.png" alt="Image de fond" className="w-full h-full fixed bg-cover blur-sm -z-10" />
        <div className="w-full h-full pt-20 p-2 sm:p-10 max-w-7xl mx-auto !pb-64">
          <section className="w-full h-fit mx-auto relative z-10 bg-white rounded-xl sm:mt-14 sm:rounded-3xl shadow-lg p-4 lg:p-8 flex space-x-4 lg:space-x-10 slide-in">
            <div className="w-full md:w-3/5 p-2 sm:p-5">
              {sent && (
                <div className="mx-auto max-w-2xl flex flex-col items-center my-40 text-palette-blue">
                  <p className="text-center text-2xl font-semibold">Votre message a été envoyé avec succès !</p>
                  <p className="text-center">Nous vous contacterons bientôt.</p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-24 h-24">
                    <path
                      fillRule="evenodd"
                      d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
              {!sent && (
                <>
                  <h4 className="leading-snug">{texts.form.title}</h4>
                  <p className="mt-2 mb-8 text-md">{texts.form.subtitle}</p>
                  <label className="text-sm font-bold">{texts.form.label1}</label>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleForm();
                    }}
                  >
                    <input
                      id="nom"
                      required
                      onChange={(e) => setForm({ ...form, [e.target.id]: e.target.value })}
                      placeholder={texts.form.placeholder1}
                      className="w-full rounded-none text-sm mb-5 outline-0 border-b border-gray-400 px-4 py-2 placeholder:text-gray-400"
                    />
                    <label className="text-sm font-bold">{texts.form.label3}</label>
                    <input
                      id="email"
                      required
                      onChange={(e) => setForm({ ...form, [e.target.id]: e.target.value })}
                      placeholder={texts.form.placeholder3}
                      className="w-full rounded-none text-sm mb-5 outline-0 border-b border-gray-400 px-4 py-2 placeholder:text-gray-400"
                    />
                    <label className="text-sm font-bold">{texts.form.label4}</label>
                    <textarea
                      id="message"
                      required
                      onChange={(e) => setForm({ ...form, [e.target.id]: e.target.value })}
                      rows={4}
                      placeholder={texts.form.placeholder4}
                      className="w-full rounded-none text-sm mb-5 outline-0 border-b border-gray-400 px-4 py-2 placeholder:text-gray-400"
                    />
                    <button type="submit" className="w-full rounded-lg bg-palette-dark-blue text-white font-semibold text-center px-6 py-3">
                      {texts.form.button}
                    </button>
                  </form>
                </>
              )}
              <address className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 justify-evenly items-center text-sm sm:text-base text-palette-blue mt-5">
                <div className="flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                  <a href={"tel:" + texts.phone} className="hover:underline not-italic">
                    {texts.phone}
                  </a>
                </div>
                <div className="flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                  <a href={"mailto:" + texts.mail} className="hover:underline not-italic">
                    {texts.mail}
                  </a>
                </div>
              </address>
            </div>
            <div className="relative w-2/5 hidden md:block  z-10">
              <img width={1290} height={1656} src={"/images/map_location.png"} className="object-cover shadow-xl h-full rounded-3xl" />
              <address className="p-4 bg-white bg-opacity-40 backdrop-blur-sm rounded-3xl hidden md:block absolute w-48 top-2 left-2 z-20 text-blue-500 ">
                <a href="https://goo.gl/maps/38p9pCtqfBL9WFgx6" target="_blank" className="text-xs leading-snug hover:underline">
                  <span className="font-bold">{texts.location.rue}</span> {texts.location.ville} {texts.location.region}
                </a>
              </address>
            </div>
            <div className="absolute right-0 top-0 bottom-0 w-0 md:w-48 bg-palette-dark-blue rounded-r-xl sm:rounded-r-3xl"></div>
          </section>
          <section className="w-full h-full p-4 md:p-0 mt-20 max-w-6xl mx-auto slide-in">
            <h5 className="my-5 text-center">{texts.title2}</h5>
            <div className="w-full mx-auto max-w-7xl items grid grid-cols-1 md:grid-cols-3 gap-2 lg:gap-6">
              {texts.cards.map((card, k) => (
                <div
                  key={"card_" + k}
                  className={
                    "relative flex flex-col group bg-gradient-to-b from-transparent to-zinc-800 justify-end bg-opacity-80 items-center h-52 w-full p-4 rounded-2xl "
                  }
                >
                  <img
                    alt="back"
                    className="absolute w-full h-full bottom-0 object-cover -z-10 rounded-2xl"
                    src={
                      card.service === 0
                        ? "/images/home/infrastructure_card.jpg"
                        : card.service === 1
                        ? "/images/home/dev_card.jpg"
                        : "/images/home/cloud_card.jpg"
                    }
                  />
                  <img src="/images/logo_black.svg" alt="logo" className="absolute top-2 left-2 w-10 h-10 opacity-40" />
                  <button
                    onClick={(e) => {
                      navigator.clipboard.writeText(card.text);
                      document.getElementById("button_" + card.text).classList.remove("hover:bg-opacity-20");
                      document.getElementById("button_" + card.text).classList.remove("hover:bg-white");
                      document.getElementById("button_" + card.text).classList.add("bg-white");
                      document.getElementById("copy_icon_" + card.text).classList.add("hidden");
                      document.getElementById("check_icon_" + card.text).classList.remove("hidden");
                      setTimeout(() => {
                        document.getElementById("button_" + card.text).classList.add("hover:bg-opacity-20");
                        document.getElementById("button_" + card.text).classList.add("hover:bg-white");
                        document.getElementById("button_" + card.text).classList.remove("bg-white");
                        document.getElementById("copy_icon_" + card.text).classList.remove("hidden");
                        document.getElementById("check_icon_" + card.text).classList.add("hidden");
                      }, 2000);
                    }}
                    id={"button_" + card.text}
                    className="p-2 rounded-full opacity-0 group-hover:opacity-100 border transition-all duration-300 mb-0 group-hover:mb-2 cursor-pointer hover:bg-white hover:bg-opacity-20 backdrop-blur-sm"
                  >
                    <svg
                      id={"copy_icon_" + card.text}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="text-white w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 01-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0113.5 1.5H15a3 3 0 012.663 1.618zM12 4.5A1.5 1.5 0 0113.5 3H15a1.5 1.5 0 011.5 1.5H12z"
                        clipRule="evenodd"
                      />
                      <path d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 019 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0116.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625v-12z" />
                      <path d="M10.5 10.5a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963 5.23 5.23 0 00-3.434-1.279h-1.875a.375.375 0 01-.375-.375V10.5z" />
                    </svg>
                    <svg
                      id={"check_icon_" + card.text}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-palette-text hidden"
                    >
                      <path
                        fillRule="evenodd"
                        d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <p className="text-white text-xl">{card.text}</p>
                </div>
              ))}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
