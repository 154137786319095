import texts from "../data/societe.json";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/home/footer";
import NavBar from "../components/navigation/navBar";
import BallsSections from "../components/sections/ballsSection";
import { Helmet } from "react-helmet";

export default function Societe({}) {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [backgroundPos, setBackgroundPos] = useState(["-35.36% 7.12%", "-28.72% 11.44%", "-4.38% 1.2%"]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Add event listener
    document.addEventListener("mousemove", (e) => parallax(e, 0));
  }, []);

  // Magic happens here
  function parallax(e, index) {
    let _w = window.innerWidth / 2;
    let _h = window.innerHeight / 2;
    let _mouseX = e.clientX;
    let _mouseY = e.clientY;
    let _depth1 = `${0 - (_mouseX - _w) * 0.03}% ${0 - (_mouseY - _h) * 0.03}%`;
    let _depth2 = `${0 - (_mouseX - _h) * 0.015}% ${0 - (_mouseY - _w) * 0.015}%`;
    let _depth3 = `${0 - (_mouseX - _w) * 0.005}% ${0 - (_mouseY - _h) * 0.005}%`;
    setBackgroundPos([_depth1, _depth2, _depth3]);
  }

  useEffect(() => {
    document.addEventListener("scroll", onScroll, false);
    return () => {
      document.removeEventListener("scroll", onScroll, false);
    };
  }, []);

  function onScroll(e) {
    let val = window.scrollY / 100;
    if (val !== scrollPosition) {
      setScrollPosition(val);
    }
  }

  return (
    <>
      <Helmet>
        <title>La société - CCO</title>
        <meta name="description" content="Découvrez l'histoire de CCO, notre équipe et nos valeurs." />
        <meta name="keywords" content="société, histoire, équipe, valeurs" />
      </Helmet>
      <NavBar />
      <main className="w-full overflow-hidden">
        <section className="flex flex-col max-w-5xl mx-auto space-y-24 py-24">
          <h2 className="text-5xl sm:text-6xl mt-10 md:text-8xl px-2 tracking-tight font-bold sm:font-outline sm:text-transparent sm:mb-20">
            {texts.about.title}
          </h2>
          {texts.about.sections.map((e, k) => (
            <div
              key={"about_" + k}
              className={
                "flex justify-between px-4 md:max-w-none w-full mx-auto items-center " + (k % 2 === 0 ? "flex-col md:flex-row-reverse" : "flex-col md:flex-row")
              }
            >
              <div id={"section_" + k} className="relative flex m-10 items-center justify-center w-fit md:w-2/4">
                <img src={e.img} style={{ translate: backgroundPos[2], width: "350px" }} className="shadow rounded-full bg-gray-100" />
                <div
                  style={{ translate: backgroundPos[0] }}
                  className={
                    "w-20 md:w-36 h-20 md:h-36 shadow-xl -z-20 paralax block rounded-full bg-palette-light-purple " +
                    (k === 0 ? "bottom-8 right-0 md:right-6" : k === 1 ? "top-6 right-0 md:right-6" : "bottom-0 left-5 md:left-16")
                  }
                />
                <div
                  style={{ translate: backgroundPos[1] }}
                  className={
                    "w-36 md:w-48 h-36 md:h-48 shadow-xl -z-20 paralax block rounded-full bg-palette-dark-purple " +
                    (k === 0 ? "bottom-10 md:left-12" : k === 1 ? "bottom-0 md:bottom-8 left-0 md:left-14" : "top-6 md:left-12")
                  }
                />
                <div
                  style={{ translate: backgroundPos[2] }}
                  className={
                    "w-48 md:w-64 h-48 md:h-64 shadow-xl -z-20 paralax block rounded-full bg-palette-dark-orange " +
                    (k === 0 ? "bottom-24 md:bottom-36 right-12" : k === 1 ? "bottom-24 md:bottom-36 left-10" : "top-10 md:top-36 right-8")
                  }
                />
              </div>
              <div className={"flex flex-col space-y-2 px-1 py-5 md:w-2/4 " + (k % 2 === 0 ? "md:mr-10" : "md:ml-10")}>
                <h4>{e.subtitle}</h4>
                <p className="text-base sm:text-lg text-md text-gray-400">{e.text}</p>
              </div>
            </div>
          ))}
        </section>
        <div className="w-full h-24 sm:h-48 bg-palette-dark-purple justify-center flex overflow-hidden relative">
          <img width={1440} height={960} className="absolute z-10 blur object-cover w-full opacity-20 h-48" src={"/images/background_lights.jpg"} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#06C8CD"
            className="w-24 h-24 sm:w-48 sm:h-48 lg:w-64 lg:h-64 transition-all duration-300 ease-linear right-2/3 absolute z-20"
            style={{ top: 10 + 2.4 * (scrollPosition - 16.5) + "%" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#9B66C7"
            className="w-32 h-32 sm:w-48 sm:h-48 lg:w-64 lg:h-64 transition-all duration-300 ease-linear absolute z-20"
            style={{ top: -15 + -4 * (scrollPosition - 14.5) + "%" }}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#F8BB5E"
            className="w-28 h-28 sm:w-52 sm:h-52 lg:w-72 lg:h-72 transition-all duration-300 ease-linear left-2/3 absolute z-20"
            style={{ top: -10 + 8.3 * (scrollPosition - 14) + "%" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z"
            />
          </svg>
        </div>
        <BallsSections type={2}>
          <div className="flex justify-center flex-col-reverse md:flex-row items-center mb-20 px-2 md:px-10 md:space-x-20 max-w-6xl w-full mx-auto">
            <div className="flex flex-col items-center md:items-start space-y-4 md:space-y-10 w-full mt-10 md:mt-0">
              <h4>{texts.history.title}</h4>
              <div className="flex flex-col space-y-4 text-center md:text-justify">
                {texts.history.body.map((e, k) => (
                  <p key={"history_body_" + k}>{e}</p>
                ))}
              </div>
            </div>
            <img src="/images/logotype_label.png" className="w-4/5 md:w-2/5" alt="Logotype de CCO" />
          </div>
        </BallsSections>
        <section className="relative z-20 flex shadow-xl flex-col items-center max-w-7xl mx-2 md:mx-5 lg:mx-auto space-y-14 mb-48 bg-gray-50 rounded-xl md:rounded-3xl p-5 lg:p-16">
          <h5>{texts.equipe.title}</h5>
          <ul className="grid grid-cols-1 md:grid-flow-col lg:grid-flow-row md:grid-rows-3 lg:grid-rows-none lg:grid-cols-3 gap-x-2 lg:gap-x-5 gap-y-10">
            {texts.equipe.members.map((e, k) => (
              <li
                style={{ color: e.colorDark }}
                className={
                  "flex " +
                  (Math.floor(k % 2) === 0 ? "flex-row-reverse" : "flex-row") +
                  " " +
                  (Math.floor(k / 3) % 2 !== 0 ? "md:flex-row-reverse" : "md:flex-row")
                }
              >
                <div className="relative w-fit h-fit">
                  <div
                    style={{ backgroundColor: e.colorDark }}
                    className={"absolute w-12 h-12 rounded-full " + (k % 3 === 0 ? "right-0" : "left-0") + " " + (k % 2 === 1 ? "bottom-0" : "top-0")}
                  />
                  <div
                    style={{ backgroundColor: e.colorDark }}
                    className={"absolute w-12 h-12 rounded-full " + (k % 3 === 0 ? "-left-2" : "-right-2") + " " + (k % 2 === 1 ? "top-4" : "bottom-4")}
                  />
                  <div
                    style={{ borderColor: e.color }}
                    className={"relative rounded-full flex items-end justify-end h-28 w-28 sm:w-36 sm:h-36 border-8 overflow-hidden bg-white"}
                  >
                    <img src={"/images/societe/" + e.img} alt={"Bitmoji de " + e.name} className="h-full object-cover" />
                  </div>
                </div>
                <div
                  className={
                    "flex flex-col p-4 w-full " +
                    (Math.floor(k % 2) == 0 ? "text-right" : "text-left") +
                    " " +
                    (Math.floor(k / 3) % 2 !== 0 ? "md:items-end justify-end md:text-right" : "md:items-start md:text-left") +
                    " "
                  }
                >
                  <h6 className="text-xl font-bold">{e.name}</h6>
                  <p>{e.role}</p>
                </div>
              </li>
            ))}
          </ul>
          <Link to={"/recrutement"} className="px-4 py-2 border border-palette-text w-fit rounded-lg">
            {texts.equipe.button}
          </Link>
        </section>
      </main>
      <Footer />
    </>
  );
}
