import { useEffect } from "react";
import { Helmet } from "react-helmet";
import texts from "../../data/infrastructure.json";
import Banner from "../../components/services/banner";
import NavBar from "../../components/navigation/navBar";
import Companies from "../../components/sections/comapnies";
import CompanyLogo from "../../components/sections/companyLogo";
import BallsSections from "../../components/sections/ballsSection";
import ServiceStructure from "../../components/services/structure";

export default function Infrastructure({}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Infrastructure - CCO</title>
        <meta
          name="description"
          content="Le service d'infrastructure de CCO vous propose des solutions de stockage, de sauvegarde, de sécurité et de virtualisation. Découvrez nos partenaires et nos solutions."
        />
        <meta name="keywords" content="infrastructure, stockage, sauvegarde, sécurité, virtualisation" />
      </Helmet>
      <NavBar />
      <Banner
        color="dark-blue"
        colorHex="#059093"
        path="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z"
        title="Infrastructure"
        additionalCss=" bg-gradient-to-tr from-palette-blue to-palette-green"
      />
      <div className="h-40 sm:h-52 pointer-events-none relative -z-30"></div>
      <ServiceStructure color="dark-blue" colorHex={"#059093"} words={texts.words} mail={texts.mail}>
        <BallsSections>
          <div className="h-full flex flex-col text-center justify-center items-center mx-auto max-w-6xl pb-20">
            <h4 className="text-palette-blue slide-in">{texts.solutions.title}</h4>
            <p className="text-base sm:text-xl max-w-xl text-center px-2 mx-auto mt-4 slide-in mb-10 sm:mb-0">{texts.solutions.subtitle}</p>
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-10 p-4 sm:p-8 md:p-16">
              {texts.solutions.items.map((item) => (
                <li className="flex items-start flex-row space-x-5 w-full max-w-md slide-in">
                  <div className="p-4 md:p-6 h-fit w-fit shadow flex gradient-blue rounded-xl md:rounded-3xl transition-all duration-200 ease-in-out hover:from-white hover:to-white border hover:border-palette-blue group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-8 md:w-12 h-8 md:h-12 text-white group-hover:text-palette-blue transition-all duration-200 ease-in-out"
                    >
                      {item.pathSvg2 && <path d={item.pathSvg2} />}
                      <path fillRule="evenodd" d={item.pathSvg} clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="flex flex-col items-center text-left md:items-start space-y-2">
                    <h4 className="w-full">{item.title}</h4>
                    <p className="w-full">{item.subtitle}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </BallsSections>
        <Companies additionalCss="from-palette-dark-blue to-palette-blue" title={texts.partenaires.title}>
          <CompanyLogo path={"/images/logos_infra/dell.png"} pathColor={"/images/logos_infra/dell_color.png"} width={220.61} height={64} alt="Logo de Dell" />
          <CompanyLogo path={"/images/logos_infra/apple.png"} pathColor={"/images/logos_infra/apple_color.png"} width={52.11} height={64} alt="Logo d'Apple" />
          <CompanyLogo
            path={"/images/logos_infra/veeam.png"}
            pathColor={"/images/logos_infra/veeam_color.png"}
            width={170.85}
            height={64}
            alt="Logo de Veeam"
          />
          <CompanyLogo path={"/images/logos_infra/HPe.png"} pathColor={"/images/logos_infra/HPe_color.png"} width={170.85} height={64} alt="Logo de HPe" />
          <CompanyLogo
            path={"/images/logos_infra/sophos.png"}
            pathColor={"/images/logos_infra/sophos_color.png"}
            width={239.51}
            height={72}
            alt="Logo de Sophos"
          />
        </Companies>
        <section className="w-full overflow-hidden relative z-10 flex flex-col space-y-20 bg-white px-4 py-16 md:py-28">
          <article className="flex flex-col md:flex-row md:space-x-2 lg:space-x-4 max-w-7xl mx-auto">
            <div className="flex flex-col max-w-xl space-y-3 sm:p-5 justify-center mb-5 md:mb-0">
              <h3>{texts.info.title1}</h3>
              <p className="text-base lg:text-xl text-justify text-gray-400">{texts.info.subtitle1}</p>
            </div>
            <img
              src={texts.info.img1}
              className="object-cover md:w-2/5 bg-gray-200 rounded-xl md:rounded-r-3xl md:rounded-l-none"
              alt="Technicien en train de repondre à un client"
            />
          </article>
          <article className="flex flex-col-reverse md:flex-row md:space-x-4 lg:space-x-4  max-w-7xl mx-auto">
            <img src={texts.info.img2} className="object-cover md:w-2/5 bg-gray-200 rounded-xl md:rounded-l-3xl md:rounded-r-none" alt="Collage des serveurs" />
            <div className=" flex flex-col max-w-xl space-y-3 sm:p-5 justify-center mb-5 md:mb-0">
              <h3>{texts.info.title2}</h3>
              <p className="text-base lg:text-xl text-justify text-gray-400">{texts.info.subtitle2}</p>
            </div>
          </article>
        </section>
      </ServiceStructure>
    </>
  );
}
