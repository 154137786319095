export default function Companies({ title, additionalCss, ...props }) {
  return (
    <section
      className={
        "relative text-center z-10 bg-gradient-to-b flex flex-col items-center space-y-10 p-14 text-white shadow-lg " +
        additionalCss
      }
    >
      <h1 className="text-lg">{title}</h1>
      <div className="flex flex-col lg:flex-row w-full space-y-8 lg:space-y-0 justify-between max-w-7xl mx-auto items-center">{props.children}</div>
    </section>
  );
}
