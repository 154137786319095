import { useEffect, useState } from "react";
import Footer from "../components/home/footer";
import Header from "../components/home/header";
import Location from "../components/home/location";
import Services from "../components/home/services";
import NavBar from "../components/navigation/navBar";
import Testimonials from "../components/home/testimonials";
import { Helmet } from "react-helmet";

export default function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", onScroll, false);
    return () => {
      document.removeEventListener("scroll", onScroll, false);
    };
  }, []);

  function onScroll(e) {
    let val = window.scrollY / 100;
    if (val !== scrollPosition) {
      setScrollPosition(val);
    }
  }

  return (
    <>
      <Helmet>
        <title>CCO - Services Informatiques</title>
        <meta
          name="description"
          content="On propose des services de maintenance informatique à tous les types d’entreprises. Nous sommes basé à Villefranche-de-Rouergue !"
        />
        <meta name="keywords" content="maintenance informatique, dépannage informatique, Villefranche-de-Rouergue, Aveyron, CCO, services informatiques" />
      </Helmet>
      <NavBar />
      <main>
        <Header />
        <div className="header hidden sm:block pointer-events-none relative -z-30"></div>
        <Services />
        <Location scrollPosition={scrollPosition} />
        <Testimonials scrollPosition={scrollPosition} />
      </main>
      <Footer />
    </>
  );
}
