import texts from "../../data/home.json";
import { isFirefox } from "../../helpers/functions";

export default function Location({ scrollPosition }) {
  return (
    <section className="relative h-screen w-full overflow-hidden bg-white z-10">
      <img width={1440} height={800} src="/images/blur_background.png" alt="blur" className="w-full h-full absolute blur-sm -z-20" />
      <div className="relative h-full flex flex-col lg:flex-row justify-center lg:space-x-10 items-center max-w-7xl p-5 py-20 md:p-16 mx-auto space-y-8 pb-20">
        <div className="relative z-20 flex lg:w-2/4 flex-col justify-center space-y-10">
          <h2>{texts.location.title}</h2>
          <div className="flex items-center space-x-4 text-blue-500 px-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path
                fillRule="evenodd"
                d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                clipRule="evenodd"
              />
            </svg>
            <address>
              <a href="https://goo.gl/maps/38p9pCtqfBL9WFgx6" target="_blank" className="leading-tight text-sm hover:underline">
                <span className="font-bold">{texts.location.rue}</span>
                <br />
                {texts.location.ville}
                <br />
                {texts.location.region}
              </a>
            </address>
          </div>
        </div>
        <div className="w-full lg:w-2/4 h-2/4 lg:h-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            width="500px"
            className="-right-52 -bottom-24 opacity-30 blur-sm absolute -z-10"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
          </svg>
          <img
            src="/images/location_france.png"
            alt="map"
            width={1440}
            height={1464.87}
            className={
              isFirefox === true
                ? "w-full mt-20"
                : "relative ml-auto transition-all -mt-5 sm:-mt-24 sm:w-3/4 md:w-full md:mt-0 duration-300 ease-linear lg:h-full object-cover overflow-visible"
            }
            style={{ top: 0 - (scrollPosition - 18) * 1 + "%", minWidth: "300px" }}
          />
        </div>
      </div>
    </section>
  );
}
