import texts from "../../data/home.json";
import { useEffect, useState } from "react";

export default function Testimonials({}) {
  const [step, setStep] = useState(0);
  const testimonials = texts.testimonials.cards;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // Loop over the entries
      entries.forEach((entry) => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          if (entry.target.id === "ball_0") {
            for (let index = 0; index < 6; index++) {
              setTimeout(() => {
                document.getElementById("ball_" + index).classList.add("bounce-in");
                document.getElementById("ball_" + index).classList.remove("opacity-0");
                document.getElementById("ball_" + index).classList.remove("bottom-5");
                document.getElementById("ball_" + index).classList.add("-bottom-40");
              }, index * 100);
            }
          }
        }
      });
    });
    observer.observe(document.getElementById("ball_0"));
  });

  return (
    <section className="relative h-screen w-full bg-white z-10 overflow-hidden">
      <div className="h-full flex flex-col justify-center items-center mx-auto max-w-6xl pb-20">
        <h4 className="px-2 text-center">{texts.testimonials.title}</h4>
        <div className="relative my-12 w-full overflow-hidden">
          <ul className="relative transition-all duration-300 ease-in-out w-fit h-full sm:space-x-6 flex items-center" style={{ left: -(408 * step) }}>
            {testimonials.map((e, k) => (
              <TestimonialCard key={"testimonial_" + k} testimonial={e} />
            ))}
          </ul>
        </div>
        <div className="flex w-full justify-between px-4 md:px-20">
          <div className="relative flex items-center space-x-4">
            {testimonials.map((e, k) => (
              <div
                key={"testimonial_div_" + k}
                onClick={() => setStep(k < testimonials.length - 1 ? k : testimonials.length - 2)}
                className="w-4 h-4 cursor-pointer rounded-full bg-gray-300"
              />
            ))}
            <div
              className="absolute transition-all duration-300 ease-in-out w-12 h-4 rounded-full bg-palette-blue"
              style={{ marginLeft: 32 * step, marginRight: 32 * (testimonials.length - 3) - 32 * step }}
            />
          </div>
          <div className="flex items-center space-x-10 text-gray-400">
            <div onClick={() => setStep(step - 1 >= 0 ? step - 1 : 0)} className="p-3 cursor-pointer rounded-full hover:bg-gray-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
            </div>
            <div onClick={() => setStep(step + 1 < testimonials.length - 1 ? step + 1 : step)} className="p-3 cursor-pointer rounded-full hover:bg-gray-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        id="ball_0"
        className="w-64 h-64 transition-all duration-300 absolute mb-10 bottom-5 opacity-0 left-16 z-10 rounded-full bg-palette-orange sm:bg-palette-dark-orange"
      />
      <div
        id="ball_2"
        className="w-80 hidden sm:block h-80 transition-all duration-300 absolute mb-14 bottom-5 opacity-0 -left-24 z-10 rounded-full bg-palette-orange"
      />
      <div id="ball_4" className="w-64 h-64 transition-all duration-300 absolute bottom-5 opacity-0 left-40 z-10 rounded-full bg-palette-blue" />
      <div
        id="ball_1"
        className="w-96 hidden sm:block h-96 transition-all duration-300 absolute mb-4 bottom-5 opacity-0 -right-40 z-10 rounded-full bg-palette-dark-purple"
      />
      <div id="ball_3" className="w-52 h-52 transition-all duration-300 absolute mb-6 bottom-5 opacity-0 right-52 z-10 rounded-full bg-palette-light-purple" />
      <div
        id="ball_5"
        className="w-72 hidden sm:block h-72 transition-all duration-300 absolute mb-2 bottom-5 opacity-0 right-10 z-10 rounded-full bg-palette-blue"
      />
    </section>
  );
}

function TestimonialCard({ testimonial }) {
  return (
    <li className="rounded-2xl h-full w-80 ml-6 mr-16 sm:mx-0 sm:w-96 p-5 flex flex-col space-y-4 bg-palette-light-background">
      <div className="flex items-center space-x-2">
        <div className="flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
            <path
              fillRule="evenodd"
              d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="flex flex-col flex-grow">
          <p className="font-semibold text-lg">{testimonial.nom}</p>
          <p className="text-sm">{testimonial.location}</p>
        </div>
      </div>
      <div className="space-y-1">
        {testimonial.commentaire.map((e) => (
          <p>{e}</p>
        ))}
      </div>
    </li>
  );
}
