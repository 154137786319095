import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function NavBar({}) {
  const location = useLocation();
  const page = location.pathname;

  useEffect(() => {
    if (window.innerWidth > 768) {
      document.getElementById("popover-menu")?.remove();
    } else {
      document.getElementById("menu")?.remove();
    }

    window.addEventListener("resize", handleNavBar);
    return () => {
      window.removeEventListener("resize", handleNavBar);
    };
  }, []);

  function handleNavBar() {
    if (window.innerWidth > 768 && document.getElementById("popover-menu") === null) {
      window.location.reload();
    } else if (window.innerWidth <= 768 && document.getElementById("menu") === null) {
      window.location.reload();
    }
  }

  function toggleNavbar() {
    let active = !document.getElementById("popover-menu").classList.contains("h-0");
    if (!active) {
      document.getElementById("popover-menu").classList.remove("h-0");
      document.getElementById("popover-menu").classList.remove("opacity-0");
      document.getElementById("popover-menu").classList.remove("pointer-events-none");
      document.getElementById("popover-menu").classList.add("p-4");

      document.getElementById("chevron-up").classList.remove("hidden");
      document.getElementById("burguer-menu").classList.add("hidden");
      setTimeout(() => {
        document.addEventListener("click", toggleNavbar, false);
      }, 200);
    } else {
      document.getElementById("popover-menu").classList.add("h-0");
      document.getElementById("popover-menu").classList.add("opacity-0");
      document.getElementById("popover-menu").classList.add("pointer-events-none");
      document.getElementById("popover-menu").classList.remove("p-4");

      document.removeEventListener("click", toggleNavbar, false);
      document.getElementById("chevron-up").classList.add("hidden");
      document.getElementById("burguer-menu").classList.remove("hidden");
    }
  }

  return (
    <nav className="md:top-2 fixed w-full z-30 left-1/2 -translate-x-1/2 max-w-5xl backdrop-blur md:backdrop-blur-0">
      <menu
        id="popover-menu"
        className="flex pointer-events-none text-center h-0 text-white md:hidden md:rounded-3xl w-full md:w-4/6 items-center opacity-0 transition-all duration-300 ease-in-out bg-black bg-opacity-20 "
      >
        <div className="flex flex-col w-full items-center">
          <Link to="/" onClick={toggleNavbar} className="hover:underline cursor-pointer p-2">
            Accueil
          </Link>
          <Link to="/societe" onClick={toggleNavbar} className="hover:underline cursor-pointer p-2">
            À propos
          </Link>
          <Link to="/recrutement" onClick={toggleNavbar} className="hover:underline cursor-pointer p-2">
            Recrutement
          </Link>
          <Link to="/engagements" onClick={toggleNavbar} className={"hover:underline cursor-pointer p-2"}>
            Nos engagements
          </Link>
        </div>
        <div className="flex flex-col w-full items-center">
          <Link to="/services/infrastructure" onClick={toggleNavbar} className="flex space-x-2 items-center hover:underline cursor-pointer p-2">
            <img src="/images/logo_infra.svg" alt="logo_infrastructure" width={24} height={24} />
            <p>Infrastructure</p>
          </Link>
          <Link to="/services/cloud" onClick={toggleNavbar} className="flex space-x-2 items-center hover:underline cursor-pointer p-2">
            <img src="/images/logo_cloud.svg" alt="logo_cloud" width={24} height={24} />
            <p>Cloud</p>
          </Link>
          <Link to="/services/developpement" onClick={toggleNavbar} className="flex space-x-2 items-center p-2">
            <img src="/images/logo_dev.svg" alt="logo_dev" width={24} height={24} />
            <p>Développement</p>
          </Link>
          <Link to="/contact" onClick={toggleNavbar} className="hover:underline cursor-pointer p-2">
            Contact
          </Link>
        </div>
      </menu>
      <div className="flex justify-between w-full md:rounded-full md:w-5/6 items-center px-6 py-2 md:px-2 md:py-1 bg-black bg-opacity-20 mx-auto md:backdrop-blur">
        <Link to="/" className="flex justify-center w-fit z-40 items-center rounded-full p-2 bg-white">
          <img width={152} height={152} src="/images/logo.svg" alt="logo" className="h-6 w-6 md:h-8 md:w-8" />
        </Link>
        <div id="menu" className="items-center relative justify-end space-x-2 hidden md:flex">
          <Link to="/" className={"text-white group " + (page === "/" ? "font-semibold" : "")}>
            <p className="group-hover:bg-white px-4 py-3  group-hover:bg-opacity-20  rounded-full">Accueil</p>
            {page === "/" && <div className="h-0.5 w-2/3 mx-auto bg-white" />}
          </Link>
          <div className={"group  cursor-pointer text-white " + (page.includes("services") ? "font-semibold" : "")}>
            <p className="group-hover:bg-white px-4 py-3  group-hover:bg-opacity-20  rounded-full">Services</p>
            {page.includes("services") && <div className="h-0.5 w-2/3 mx-auto bg-white" />}
            <div className=" bg-white mt-2.5 top-8 hidden group-hover:flex absolute shadow-xl rounded-xl p-2 space-y-1 flex-col font-semibold">
              <Link to="/services/infrastructure" className={"px-2 py-1 text-palette-blue hover:underline flex items-center space-x-1"}>
                <img src="/images/logo_infra.svg" alt="logo_infrastructure" width={24} height={24} />
                <p>Infrastructure</p>
              </Link>
              <Link to="/services/cloud" className={"px-2 py-1 text-palette-orange hover:underline flex items-center space-x-1"}>
                <img src="/images/logo_cloud.svg" alt="logo_infra" width={24} height={24} />
                <p>Cloud</p>
              </Link>
              <Link to="/services/developpement" className={"px-2 py-1 text-palette-purple hover:underline flex items-center space-x-1"}>
                <img src="/images/logo_dev.svg" alt="logo_dev" width={24} height={24} />
                <p>Développement</p>
              </Link>
            </div>
          </div>
          <div className="relative cursor-pointer group text-white ">
            <p
              className={
                "group-hover:bg-white px-4 py-3 group-hover:bg-opacity-20  rounded-full " +
                (page === "/societe" || page === "/recrutement" ? "font-semibold" : "")
              }
            >
              La société
            </p>
            {(page === "/societe" || page === "/recrutement" || page === "/engagements") && <div className="h-0.5 w-2/3 mx-auto bg-white" />}
            <div className=" absolute top-8 bg-white mt-2.5 hidden group-hover:flex   shadow-xl rounded-xl p-2 space-y-1 flex-col">
              <Link to="/societe" className={"px-3 py-2 hover:underline  text-palette-blue "}>
                À propos
              </Link>
              <Link to="/recrutement" className={"px-3 py-2 hover:underline  text-palette-blue"}>
                Recrutement
              </Link>
              {/* <Link to="/engagements" className={"px-3 py-2 hover:underline  text-palette-blue"}>
                Nos engagements
              </Link> */}
            </div>
          </div>
          <Link to="/contact" className={"text-white group " + (page === "/contact" ? "font-semibold" : "")}>
            <p className="group-hover:bg-white px-4 py-3  group-hover:bg-opacity-20  rounded-full ">Contact</p>
            {page === "/contact" && <div className="h-0.5 w-2/3 mx-auto bg-white" />}
          </Link>
          <Link to="/assistance" className={"px-6 py-3 bg-white text-palette-blue hover:underline rounded-full "}>
            Assistance
          </Link>
        </div>
        <button onClick={toggleNavbar} className="hover:bg-white p-2 hover:bg-opacity-20 rounded-full md:hidden">
          <svg id="burguer-menu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" className="w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          <svg id="chevron-up" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" className="hidden w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
          </svg>
        </button>
      </div>
    </nav>
  );
}
