import texts from "../data/engagements.json";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/home/footer";
import NavBar from "../components/navigation/navBar";
import { Helmet } from "react-helmet";

export default function NosEngagements({}) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", onScroll, false);
    return () => {
      document.removeEventListener("scroll", onScroll, false);
    };
  }, []);

  function onScroll(e) {
    let val = window.scrollY / 100;
    document.getElementById("title").style.opacity = 1 - val * 0.2;
    document.getElementById("title").style.scale = 1 - val * 0.02;

    if (val > 5.17) {
      document.getElementById("card_1").style.scale = 1 + (3.17 - val) * 0.02;
      document.getElementById("card_1").style.top = 96 + (5.17 - val) * 6 + "px";
    }
    if (val > 12.2) {
      document.getElementById("card_2").style.scale = 1 + (10.2 - val) * 0.02;
      document.getElementById("card_2").style.top = 112 + (12.2 - val) * 4 + "px";
    }
    if (val > 19.5) {
      document.getElementById("card_3").style.scale = 1 + (15.8 - val) * 0.02;
      document.getElementById("card_3").style.top = 144 + (19.5 - val) * 2 + "px";
    }
  }
  return (
    <>
      <Helmet>
        <title>Responsabilité Sociétale des Entreprises - CCO</title>
        <meta
          name="description"
          content="CCO est particulièrement sensible aux enjeux environnementaux et a depuis sa création, intégrée au mieux les principes de la RSE."
        />
        <meta name="keywords" content="CCO, RSE, responsabilité, sociétale, entreprise, environnement, social, économique" />
      </Helmet>
      <NavBar />
      <div className="h-36 w-full bg-palette-dark-purple relative -z-10 shadow-inner">
        <img width={1440} height={960} className="absolute z-10 blur object-cover w-full opacity-20 h-48" src={"/images/background_lights.jpg"} />
      </div>
      <main className="flex flex-col items-center pt-16 space-y-40  bg-gray-50 h-full">
        <div id="title" className="w-fit text-center p-5 sticky top-20">
          <h4 className="text-3xl sm:text-4xl md:text-5xl leading-tight mx-auto">Responsabilité Sociétale des Entreprises</h4>
          <p className="max-w-xl mt-10 text-base sm:text-lg mx-auto">
            CCO est particulièrement sensible aux enjeux environnementaux et a depuis sa création, intégrée au mieux les principes de la RSE.
          </p>
          <p className="mt-4 text-lg mx-auto">CCO a de cesse de s’améliorer et de sensibiliser ses équipes.</p>
        </div>
        {/* CARD 01 ------------- */}
        <article
          style={{ height: "560px" }}
          id="card_1"
          className="bg-gray-200 transition-all ease-linear rounded-3xl shadow-lg w-full max-w-6xl flex overflow-hidden sticky top-24"
        >
          <img src="/images/header_background.jpg" className="w-full h-full absolute object-cover opacity-5 top-0 bottom-0" />
          <h4 className="text-10xl opacity-75 text-slate-400 absolute z-10 top-2 leading-none left-4">01</h4>
          <div className="w-2/4 flex flex-col relative z-10 mt-24 p-10 space-y-6">
            <h3 className="font-bold text-slate-900 leading-none">PILIER ENVIRONNEMENTAL</h3>
            <div className="space-y-3 ml-2">
              <div>
                <p className="font-bold">Système de transport respectueux de l'environnement</p>
                <ul className="ml-8 list-disc">
                  <li>1 véhicule électrique</li>
                  <li>1 véhicule hybride</li>
                </ul>
              </div>
              <div>
                <p className="font-bold">Développer le recyclage et la réduction des déchets</p>
                <ul className="ml-8 list-disc">
                  <li>Tri sélectif</li>
                  <li>Réduction de l’utilisation du papier</li>
                  <li>Limitation d’envoi de courrier électronique</li>
                </ul>
              </div>
              <div>
                <p className="font-bold">Repenser la stratégie d’achat</p>
                <ul className="ml-8 list-disc">
                  <li>Grouper les commandes</li>
                  <li>Limiter les livraisons</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-2/4 flex flex-col flex-1 justify-between items-end relative z-10">
            <img src="/images/logo.svg" width={"36px"} height={"32px"} className="m-4" />
            <div className="h-full w-full bg-slate-800 rounded-tl-5xl"></div>
          </div>
        </article>
        {/* CARD 02 ------------- */}
        <article
          style={{ height: "560px" }}
          id="card_2"
          className="bg-gray-200 transition-all ease-linear rounded-3xl shadow-lg w-full max-w-6xl flex overflow-hidden sticky top-28"
        >
          <img src="/images/header_background.jpg" className="w-full h-full absolute object-cover opacity-5 top-0 bottom-0" />
          <h4 className="text-10xl opacity-75 text-slate-400 absolute z-10 top-2 leading-none right-4">02</h4>
          <div className="w-2/4 flex flex-col flex-1 justify-between items-start relative z-10">
            <img src="/images/logo.svg" width={"36px"} height={"32px"} className="m-4" />
            <div className="h-full w-full bg-slate-800 rounded-tr-5xl"></div>
          </div>
          <div className="w-2/4 flex flex-col relative z-10 mt-24 p-10 space-y-6">
            <h3 className="font-bold text-slate-900 leading-none uppercase">PILIER Sociétal</h3>
            <div className="space-y-3 ml-2">
              <div>
                <p className="font-bold">Mise en place du télétravail</p>
              </div>
              <div>
                <p className="font-bold">Diversité des profils</p>
                <ul className="ml-8 list-disc">
                  <li>Travail sur la parité homme/femme</li>
                  <li>Mixité sociale ( catégories socioprofessionnelles, cultures, nationalités, tranches d’âge différentes )</li>
                </ul>
              </div>
              <div>
                <p className="font-bold">Mise à disposition d’un espace détente pour les collaborateurs</p>
              </div>
              <div>
                <p className="font-bold">Utilisation des services de structure d’aide à l’insertion professionnelle</p>
              </div>
            </div>
          </div>
        </article>
        {/* CARD 03 ------------- */}
        <article
          style={{ height: "560px" }}
          id="card_3"
          className="bg-gray-200 transition-all ease-linear rounded-3xl shadow-lg w-full max-w-6xl flex overflow-hidden sticky top-36"
        >
          <img src="/images/header_background.jpg" className="w-full h-full absolute object-cover opacity-5 top-0 bottom-0" />
          <h4 className="text-10xl opacity-75 text-slate-400 absolute z-10 top-2 leading-none left-4">03</h4>
          <div className="w-2/4 flex flex-col relative z-10 mt-24 p-10 space-y-6">
            <h3 className="font-bold text-slate-900 leading-none">PILIER ECONOMIQUE</h3>
            <div className="space-y-3 ml-2">
              <div>
                <p className="font-bold">Mise en place d’ampoules basse consommation</p>
              </div>
              <div>
                <p className="font-bold">Veille automatique et/ou extinction du matériel non utilisé</p>
              </div>
              <div>
                <p className="font-bold">Régulation de la température ambiante (19°/20°C)</p>
              </div>
              <div>
                <p className="font-bold">Favoriser la réparation</p>
              </div>
              <div>
                <p className="font-bold">Participe à la redynamisation du centre-ville</p>
              </div>
              <div>
                <p className="font-bold">
                  Entreprise implantée dans une zone de revitalisation rurale (ZRR) et située dans un quartier prioritaire de la politique de la ville (QPV)
                </p>
              </div>
            </div>
          </div>
          <div className="w-2/4 flex flex-col flex-1 justify-between items-end relative z-10">
            <img src="/images/logo.svg" width={"36px"} height={"32px"} className="m-4" />
            <div className="h-full w-full bg-slate-800 rounded-tl-5xl"></div>
          </div>
        </article>
        <div className="h-96"></div>
      </main>
      <Footer />
    </>
  );
}
