import { Link } from "react-router-dom";
import texts from "../../data/home.json";

export default function Footer({ isService = false }) {
  return (
    <div className={"relative w-full z-10 bg-palette-light-background p-5 md:p-10 " + (isService ? " sm:rounded-xl" : "")}>
      <div className="flex flex-col max-w-7xl mx-auto space-y-10">
        <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:justify-between lg:space-x-4 items-center">
          <img src="/images/logotype_label.png" width={230} alt="logotype_footer" className="mr-5" />
          <div className="flex flex-col items-center text-center lg:items-start lg:text-left space-y-5 w-full">
            <div className="flex items-center space-x-2">
              <Link
                to="https://www.facebook.com/ccoinfo/"
                target="_blank"
                className="flex items-center justify-center bg-palette-light-background cursor-pointer hover:shadow-xl rounded-full w-8 h-8"
              >
                <img alt="facebook" src="/images/facebook.png" width="48" height="48" />
              </Link>
              <Link
                to="https://fr.linkedin.com/company/cco-informatique-&-r%C3%A9seaux"
                target="_blank"
                className="flex items-center justify-center bg-palette-light-background cursor-pointer hover:shadow-xl rounded-full w-8 h-8"
              >
                <img alt="linkedin" src="/images/linkedin.png" width="48" height="48" />
              </Link>
            </div>
            <p className="max-w-sm">{texts.header.subtitle}</p>
          </div>
          <div className="grid grid-cols-2 gap-5 lg:flex lg:flex-col text-center lg:text-right w-fit justify-evenly">
            <Link to="/societe" className="cursor-pointer hover:underline">
              La société
            </Link>
            <Link to="/contact" className="cursor-pointer hover:underline">
              Contact
            </Link>
            <Link to="/recrutement" className="cursor-pointer hover:underline">
              Recrutement
            </Link>
            <Link to="/engagements" className="cursor-pointer hover:underline whitespace-nowrap">
              Nos engagements
            </Link>
            <Link to="/assistance" className="cursor-pointer hover:underline">
              Assistance
            </Link>
          </div>
          <Link to={"https://www.cybermalveillance.gouv.fr/"} target="_blank" className="cursor-pointer lg:w-1/5">
            <img src="/images/home/cybermalveillance.png" width={199.32} height={150} alt="logotype_footer" />
          </Link>
        </div>
        <hr className="mx-10" />
        <div className="flex flex-col text-center lg:flex-row  w-full space-y-5 lg:space-y-0 lg:space-x-10 font-semibold">
          <p className="flex-grow text-xs">©CCO 2018 - CCO : SARL au capital de 1000€ enregistré au RCS de Rodez sous le N° 823 502 232</p>
        </div>
      </div>
    </div>
  );
}
