import { useEffect, useState } from "react";
import texts from "../data/recrutement.json";
import Footer from "../components/home/footer";
import NavBar from "../components/navigation/navBar";
import FormRecrutement from "../components/sections/formRecrutement";
import { Helmet } from "react-helmet";

export default function Recrutement({}) {
  const [showModal, setShowModal] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showModal) document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [showModal]);

  function handleKeyDown(e) {
    if (e.code === "Escape" || (e.target && e.target.id === "background_modal")) setShowModal(null);
  }

  return (
    <>
      <Helmet>
        <title>Recrutement - CCO</title>
        <meta name="description" content="Trouvez votre place au sein de notre équipe et participez à l'aventure CCO !" />
        <meta name="keywords" content="CCO, recrutement, emploi, poste, job, candidature, offre, travail, carrière" />
      </Helmet>
      <NavBar />
      <main>
        <section className="flex flex-col space-y-10 bg-gray-50 py-24">
          <h2 className="text-5xl px-2 mt-10 md:px-14 max-w-7xl mx-auto tracking-tight font-semibold w-full slide-in">{texts.title}</h2>
          {texts.postes.length === 0 && (
            <div className=" flex items-center space-x-4 w-fit max-w-5xl mx-auto px-10 py-6 slide-in">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
              <p className="italic">Aucun poste pour le moment...</p>
            </div>
          )}
          <ul className="px-2 sm:px-10 mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {texts.postes.map((poste, k) => (
              <article
                key={"poste_" + k}
                onClick={() => setShowModal(poste)}
                className={
                  "flex flex-col bg-white cursor-pointer group rounded-2xl h-48 space-y-5 p-5 shadow transition-all justify-between duration-300 ease-in hover:shadow-xl slide-in"
                }
              >
                <div>
                  <h6 className="leading-none mb-3 group-hover:underline">{poste.title}</h6>
                  <p className="text-sm">
                    <strong>{poste.location}</strong> - {poste.type}
                  </p>
                </div>
                <div className="flex justify-between items-end">
                  <img
                    alt="logo"
                    width={32}
                    className=""
                    src={poste.service === 0 ? "/images/logo_infra.svg" : poste.service === 1 ? "/images/logo_dev.svg" : "/images/logo_cloud.svg"}
                  />
                  <p
                    className={
                      "font-semibold hover:underline cursor-pointer text-palette-" + (poste.service === 0 ? "blue" : poste.service === 1 ? "purple" : "orange")
                    }
                  >
                    Appliquer
                  </p>
                </div>
              </article>
            ))}
          </ul>
        </section>
        <section className="h-fit relative py-20">
          <img width={1440} height={1920} src="/images/blur_background.png" alt="Image de fond" className="w-full top-0 h-full absolute blur-sm" />
          <div className="w-full h-full sm:p-5 md:p-10 lg:p-20 max-w-7xl mx-auto">
            <div className="w-full h-fit mx-auto relative z-10 bg-white sm:rounded-3xl shadow-lg p-5 md:p-10 lg:px-20 flex flex-col">
              <div className="w-full sm:w-4/5">
                <h5 className="leading-snug">{texts.form.title}</h5>
                <p className="mt-2 mb-8 text-sm">{texts.form.subtitle}</p>
                <FormRecrutement />
              </div>
              <div className="absolute right-0 top-0 bottom-0 w-0 sm:w-12 md:w-32 lg:w-48 bg-palette-dark-blue rounded-r-xl sm:rounded-r-3xl"></div>
            </div>
          </div>
        </section>
      </main>
      {showModal && (
        <div
          onClick={handleKeyDown}
          id="background_modal"
          className="bg-black flex justify-center z-50 items-center bg-opacity-30 fixed w-full h-screen top-0 bottom-0 left-0 right-0"
        >
          <div className="relative max-w-3xl bg-white sm:rounded-3xl text-center flex flex-col items-center justify-between p-3 py-16 sm:p-12 w-full h-full sm:h-fit">
            <div className="flex flex-col items-center">
              <img
                alt="logo"
                width={150}
                src={
                  showModal.service === 0
                    ? "/images/logo_infra_label.svg"
                    : showModal.service === 1
                    ? "/images/logo_dev_label.svg"
                    : "/images/logo_cloud_label.svg"
                }
              />
              <h5 className="mt-5 leading-tight group-hover:underline">{showModal.title}</h5>
              <p className="text-xs sm:text-sm">
                <strong>{showModal.location}</strong> -{showModal.type}
              </p>
            </div>
            <div className="w-full text-left mt-12">
              <FormRecrutement poste={showModal.title} service={showModal.service} />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              onClick={() => {
                setShowModal(null);
              }}
              className="w-6 h-6 absolute top-6 right-6 cursor-pointer"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
