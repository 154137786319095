import { useEffect } from "react";
import { Link } from "react-router-dom";
import texts from "../../data/home.json";
import { awaitForSeconds } from "../../helpers/functions";

export default function Services({}) {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // Loop over the entries
      entries.forEach(async (entry) => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          if (entry.target.id === "card_0") {
            for (let index = 0; index < 3; index++) {
              await awaitForSeconds(0.2);
              document.getElementById("card_" + index).classList.remove("opacity-0");
              document.getElementById("card_" + index).classList.add("slide-in");
            }
          }
        }
      });
    });
    observer.observe(document.getElementById("card_0"));
  }, []);

  return (
    <section className="relative h-fit md:h-screen w-full flex flex-col items-center justify-center bg-white z-10 overflow-hidden">
      <div className="mx-auto max-w-7xl px-5 py-10 md:px-5 md:h-full lg:space-x-8 flex flex-col lg:flex-row space-y-4 lg:space-y-0 items-center justify-between">
        <div className="flex w-full lg:w-1/4 lg:h-2/4 flex-col sm:p-4 py-10 md:p-10 space-y-5 justify-center">
          <h1>{texts.services.title}</h1>
          <p className="text-lg text-palette-light-text">{texts.services.subtitle}</p>
        </div>
        <ul className="flex max-w-lg md:max-w-none items-center flex-col md:flex-row md:space-x-4 md:h-full space-y-4 md:space-y-0">
          <ServiceCard
            id="card_0"
            description={texts.services.infraSubtitle}
            name="Infrastructure"
            to="/services/infrastructure"
            imgPath="/images/home/infrastructure_card.jpg"
            bgColor="bg-palette-dark-blue"
          />
          <ServiceCard
            id="card_1"
            description={texts.services.cloudSubtitle}
            name="Cloud"
            to="/services/cloud"
            imgPath="/images/home/cloud_card.jpg"
            bgColor="bg-palette-dark-orange "
          />
          <ServiceCard
            id="card_2"
            description={texts.services.devSubtitle}
            name="Développement"
            to="/services/developpement"
            imgPath="/images/home/dev_card.jpg"
            bgColor="bg-palette-dark-purple "
          />
        </ul>
      </div>
    </section>
  );
}

function ServiceCard({ id, imgPath = "", to = "", name = "", description = "", bgColor = "" }) {
  return (
    <li id={id} style={{ maxHeight: "650px" }} className="w-full group relative rounded-3xl overflow-hidden h-96 md:h-full lg:h-3/4 opacity-0">
      <Link to={to} className="flex flex-col h-full justify-end">
        <img width={1100} height={1824} alt="bg_card" className="absolute -z-10 w-full h-full object-cover" src={imgPath} />
        <div className="absolute -z-10 w-full rounded-3xl h-full object-cover transition-all duration-1000 ease-in-out group-hover:bg-black opacity-20" />
        <div className={"w-full px-4 rounded-b-3xl sm:group-hover:space-y-2  pt-2.5 pb-5 " + bgColor}>
          <h2 className="text-white font-bold text-2xl">{name}</h2>
          <p className="text-white transition-all duration-1000 ease-in-out opacity-0 h-0 sm:group-hover:h-20 sm:group-hover:opacity-100">{description}</p>
        </div>
      </Link>
    </li>
  );
}
